import {jwtDecode} from "jwt-decode";

const API_ENTRYPOINT =
  process.env.REACT_APP_API_ENTRYPOINT || "http://api.localhost";

const AuthProvider = {
  login: ({ username, password }) => {
    const request = new Request(
      `${API_ENTRYPOINT}/admin/login`,
      {
        method: "POST",
        body: JSON.stringify({ username: username, password }),
        headers: new Headers({ "Content-Type": "application/json" }),
      }
    );
    return fetch(request)
      .then((response: Response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ token }) => {
        console.log('setting token', token);
        localStorage.setItem("token", token);
      });
  },
  logout: () => {
    localStorage.removeItem("token");
    return Promise.resolve();
  },
  checkAuth: () => {
    try {
      const token: string = localStorage.getItem("token") ?? '';

      if (token === '') {
        return Promise.reject();
      }

      const tokenExpiry: number | undefined = jwtDecode(token)?.exp;

      if (tokenExpiry !== undefined && tokenExpiry < Date.now() / 1000) {
        return Promise.reject();
      }

      return Promise.resolve();
    } catch (e) {
      // override possible jwtDecode error
      return Promise.reject();
    }
  },
  checkError: (err) => {
    if ([401, 403].includes(err?.status || err?.response?.status)) {
      localStorage.removeItem("token");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  getPermissions: () => Promise.resolve(),
};

export default AuthProvider;
