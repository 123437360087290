import React from "react";
import {ShowGuesser, FieldGuesser} from "@api-platform/admin";
import {ReferenceField, ChipField} from "react-admin";

const ShowPrize = props => (
  <ShowGuesser {...props}>
    <FieldGuesser source={"name"} />
    <FieldGuesser source={"description"} />
    <FieldGuesser source={"type"} />
    <ReferenceField label="Pot" source="pot" reference="pots">
      <ChipField source="name" />
    </ReferenceField>
  </ShowGuesser>
);

export default ShowPrize;
