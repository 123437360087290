import React from "react";
import {
  BooleanField, Button,
  ChipField,
  Datagrid,
  List,
  ReferenceField,
  ShowButton,
  TextField, TopToolbar,
} from "react-admin";
import JsFileDownloader from 'js-file-downloader';

const exporter = () => {
  const timestamp = new Date().getTime();
  new JsFileDownloader({
    url: `${process.env.REACT_APP_API_ENTRYPOINT}/entries/export/csv?timestamp=${timestamp}`,
    headers: [
      {name: 'Authorization', value: `Bearer ${localStorage.getItem("token")}`}
    ],
    withCredentials: false,
    nameCallback: function(name) {
      return 'entries.' + name;
    }
  }).then(function () {
  })
  .catch(function (error) {
    console.log(error);
  });
};

const EntriesListActions = () => (
  <TopToolbar>
    <Button label="Export" onClick={exporter} />
  </TopToolbar>
);

const ListEntries = props => (
  <List actions={<EntriesListActions />} filter={{ 'confirmed': true }} {...props}>
    <Datagrid>
      <TextField source={"givenName"} />
      <TextField source={"familyName"} />
      <ReferenceField label="Entrant" source="entrant" reference="entrants" link="show">
        <ChipField source="code" />
      </ReferenceField>
      <ReferenceField label="Prize" source="prize" reference="prizes">
        <ChipField source="name" />
      </ReferenceField>
      <TextField source={"label"} label={"Address"} />
      <TextField source={"email"} />
      <BooleanField source={"confirmed"} />
      <ShowButton />
    </Datagrid>
  </List>
);

export default ListEntries;
