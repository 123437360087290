import React from "react";
import {ShowGuesser, FieldGuesser} from "@api-platform/admin";
import {ChipField, ReferenceArrayField, SingleFieldList} from "react-admin";

const ShowPot = props => {
  return (
    <ShowGuesser {...props}>
      <FieldGuesser source={"name"} />
      <ReferenceArrayField label="Prizes" reference="prizes" source="prizes" resource="pots" sortable={false}>
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
    </ShowGuesser>
  );
};

export default ShowPot;
