import React from "react";
import {ListGuesser, FieldGuesser} from "@api-platform/admin";
import {ChipField, CreateButton, ExportButton, ReferenceField, TopToolbar} from "react-admin";

const ListActions = () => (
    <TopToolbar>
        <CreateButton />
        <ExportButton maxResults={10000} />
    </TopToolbar>
);

const ListEntrant = props => (
  <ListGuesser actions={<ListActions />} {...props}>
    <FieldGuesser source={"email"} />
    <FieldGuesser source={"code"} />
    <FieldGuesser source={"postcode"} />
    <FieldGuesser source={"givenName"} />
    <FieldGuesser source={"familyName"} />
    <ReferenceField label="Pot" source="pot" reference="pots" sortable={false}>
      <ChipField source="name" />
    </ReferenceField>
    <FieldGuesser source={"redeemed"} />
  </ListGuesser>
);

export default ListEntrant;
