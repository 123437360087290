import React from "react";
import {FieldGuesser} from "@api-platform/admin";
import {ChipField, Datagrid, EditButton, List, ReferenceArrayField, SingleFieldList} from "react-admin";

const ListPot = props => (
  <List {...props}>
    <Datagrid>
      <FieldGuesser source={"name"} />
      <ReferenceArrayField label="Prizes" reference="prizes" source="prizes" resource="pots" sortable={false}>
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <EditButton />
    </Datagrid>
  </List>
);

export default ListPot;
