import React from "react";
import {ShowGuesser, FieldGuesser} from "@api-platform/admin";
import {ImageField} from "react-admin";

const ShowMediaObject = props => {
  return (
    <ShowGuesser {...props}>
      <FieldGuesser source={"title"} />
      <ImageField source="src" title="title" sx={{ '& .RaImageField-image': { maxWidth: 400, maxHeight: 400, objectFit: 'contain' } }} />
      <FieldGuesser source={"width"} />
      <FieldGuesser source={"height"} />
    </ShowGuesser>
  );
};

export default ShowMediaObject;
