import {useChoicesContext} from "ra-core";
import {AutocompleteInput} from "react-admin";
import React from "react";

const ImageSelect = (props) => {
  const {
    selectedChoices, // an array of the selected choices records, e.g. [{ id: 123, title: 'hello world' }, { ... }],.
  } = useChoicesContext();

  const choice = selectedChoices[0] ?? null;

  return (
    <>
      <AutocompleteInput
        filterToQuery={searchText => ({ title: searchText })}
        optionText="title"
        label={props.title}
      />
      {choice &&
          <img src={choice.src} width={300} alt="" />
      }
      &nbsp;
    </>
  );
};

export default ImageSelect;
