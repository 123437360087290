import React from "react";
import {CreateGuesser, InputGuesser} from "@api-platform/admin";

const CreatePot = props => (
  <CreateGuesser {...props}>
    <InputGuesser source={"name"} />
  </CreateGuesser>
);

export default CreatePot;
