import React from "react";
import {EditGuesser, InputGuesser} from "@api-platform/admin";
import {ReferenceInput, AutocompleteInput} from "react-admin";

const EditEntrant = props => (
  <EditGuesser {...props}>
    <InputGuesser source={"email"} />
    <InputGuesser source={"postcode"} />
    <InputGuesser source={"givenName"} />
    <InputGuesser source={"familyName"} />
    <InputGuesser source={"customField1"} />
    <InputGuesser source={"customField2"} />
    <InputGuesser source={"customField3"} />
    <ReferenceInput
      source="pot"
      reference="pots"
    >
      <AutocompleteInput
        filterToQuery={searchText => ({ title: searchText })}
        optionText="name"
        label="Pot"
      />
    </ReferenceInput>
  </EditGuesser>
);

export default EditEntrant;
