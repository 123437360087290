import { Menu } from 'react-admin';
import PermMediaIcon from '@mui/icons-material/PermMedia';

export const MyMenu = () => (
  <Menu>
    <Menu.ResourceItem name="prizes" />
    <Menu.ResourceItem name="pots" />
    <Menu.ResourceItem name="entrants" />
    <Menu.ResourceItem name="media_objects" />
    <Menu.ResourceItem name="entries" />
    <Menu.Item to="/upload-entrants" primaryText="Entrant Upload" leftIcon={<PermMediaIcon />} placeholder='' />
  </Menu>
);

export default MyMenu;
