import React from "react";
import {ShowGuesser, FieldGuesser} from "@api-platform/admin";
import {ReferenceField, TextField} from "react-admin";

const ShowEntries = props => {
  return (
    <ShowGuesser actions={false}>
      <FieldGuesser source={"givenName"} />
      <FieldGuesser source={"familyName"} />
      <FieldGuesser source={"contactNumber"} />
      <FieldGuesser source={"email"} />
      <ReferenceField label="Prize" reference="prizes" source="prize" >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Prize Type" reference="prizes" source="prize" >
        <TextField source="type" />
      </ReferenceField>
      <FieldGuesser source={"company"} />
      <FieldGuesser source={"line1"} />
      <FieldGuesser source={"line2"} />
      <FieldGuesser source={"line3"} />
      <FieldGuesser source={"line4"} />
      <FieldGuesser source={"line5"} />
      <FieldGuesser source={"provinceName"} />
      <FieldGuesser source={"postalCode"} />
      <FieldGuesser source={"country"} />
      <FieldGuesser source={"label"} />
      <FieldGuesser source={"createdAt"} />
      <FieldGuesser source={"updatedAt"} />
    </ShowGuesser>
  );
};

export default ShowEntries;
