import React, { useState, useEffect } from "react";
import axios from "axios";
import { Alert, Card, CardContent } from '@mui/material';
import {Title} from 'react-admin';
import {FileUpload, FileUploadProps} from "../components/FileUpload";

const UploadEntrant = () => {
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('An error occured');
  const [pots, setPots] = useState<any[]>([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    axios.get(`${process.env.REACT_APP_API_ENTRYPOINT}/pots`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`,
        'Content-Type': 'application/ld+json'
      }
    }).then(response => {
      const potNames = response.data['hydra:member'].map((pot: any) => {
        return {
          name: pot.name
        }
      });

      setPots(potNames);
    }).catch(error => {
      console.log(error);
    });
  }

  const fileUploadProp: FileUploadProps = {
    accept: 'text/csv',
    hoverLabel: 'Click or drag to upload CSV',
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      if (
        event.target.files !== null &&
        event.target?.files?.length > 0
      ) {
        const formData = new FormData();
        formData.append('file', event.target.files[0]);

        axios.post(`${process.env.REACT_APP_API_ENTRYPOINT}/entrant/upload`, formData, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
            'Content-Type': 'multipart/form-data'
          }
        }).then(response => {
          setSuccess(true);
          setError(false);
        }).catch(error => {
          setSuccess(false);
          setError(true);
          setErrorMessage(error.message);
        })
      }
    },
    onDrop: (event: React.DragEvent<HTMLElement>) => {
      setSuccess(false);
      setError(false);
      console.log(`Drop ${event.dataTransfer.files[0].name}`)
    },
  }

  return (
    <Card>
      <Title title="Upload Entrants"/>
      <CardContent>
        {success && <Alert severity="success">
            CSV uploaded successfully
        </Alert>}
        {error && <Alert severity="error">
          {errorMessage}
        </Alert>}
        <FileUpload {...fileUploadProp} />
        <p><a href={`${process.env.REACT_APP_API_ENTRYPOINT}/entrants.csv`}>Download CSV Template</a></p>
        <p>Valid values for Pots:</p>
        <ul>
          {pots.map(item => {
            return <li key={item.name}>{item.name}</li>;
          })}
        </ul>
      </CardContent>
    </Card>
  );
};

export default UploadEntrant;
