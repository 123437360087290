import React from "react";
import {CreateGuesser, InputGuesser} from "@api-platform/admin";
import { ReferenceInput, AutocompleteInput } from "react-admin";

const CreateEntrant = props => (
  <CreateGuesser {...props}>
    <InputGuesser source={"email"} />
    <InputGuesser source={"postcode"} />
    <InputGuesser source={"givenName"} />
    <InputGuesser source={"familyName"} />
    <InputGuesser source={"customField1"} />
    <InputGuesser source={"customField2"} />
    <InputGuesser source={"customField3"} />
    <ReferenceInput
      source="pot"
      reference="pots"
    >
      <AutocompleteInput
        filterToQuery={searchText => ({ title: searchText })}
        optionText="name"
        label="Pot"
      />
    </ReferenceInput>
  </CreateGuesser>
);

export default CreateEntrant;
