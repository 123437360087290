import React from "react";
import {FieldGuesser} from "@api-platform/admin";
import {ChipField, Datagrid, EditButton, List, ReferenceField} from "react-admin";

const ListPrize = props => (
  <List {...props}>
    <Datagrid>
      <FieldGuesser source={"name"} />
      <FieldGuesser source={"type"} />
      <FieldGuesser source={"active"} />
      <ReferenceField label="Pot" source="pot" reference="pots" sortable={false}>
        <ChipField source="name" />
      </ReferenceField>
      <EditButton />
    </Datagrid>
  </List>
);

export default ListPrize;
