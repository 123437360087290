import React from "react";
import {EditGuesser} from "@api-platform/admin";
import {FileInput, FileField } from "react-admin";

const EditMediaObject = props => {
  return (
    <EditGuesser {...props}>
      <FileInput source="file" label="Image" accept="image/*">
        <FileField source="src" title="title" />
      </FileInput>
    </EditGuesser>
  )
};

export default EditMediaObject;
