import React from "react";
import {ShowGuesser, FieldGuesser} from "@api-platform/admin";
import {ReferenceField, TextField} from "react-admin";

const ShowEntrant = props => {
  return (
    <ShowGuesser {...props}>
      <FieldGuesser source={"givenName"} />
      <FieldGuesser source={"familyName"} />
      <FieldGuesser source={"email"} />
      <FieldGuesser source={"postcode"} />
      <FieldGuesser source={"code"} />
      <ReferenceField label="Pot" reference="pots" source="pot" >
        <TextField source="name" />
      </ReferenceField>
      <FieldGuesser source={"redeemed"} />
      <FieldGuesser source={"createdAt"} />
      <FieldGuesser source={"updatedAt"} />
      <FieldGuesser source={"userIdentifier"} />
    </ShowGuesser>
  );
};

export default ShowEntrant;
