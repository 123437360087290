import React from "react";
import {CreateGuesser, InputGuesser} from "@api-platform/admin";
import {ReferenceInput, AutocompleteInput, SelectInput} from "react-admin";
import {
  ClearButtons,
  FormatButtons,
  ListButtons,
  RichTextInput,
  RichTextInputToolbar
} from "ra-input-rich-text";
import {MyEditorOptions} from "./EditPrize";
import ImageSelect from "../components/ImageSelect";

const CreatePrize = ({ size, ...props }) => (
  <CreateGuesser {...props}>
    <InputGuesser source={"name"} />
    <RichTextInput
      editorOptions={MyEditorOptions}
      toolbar={<MyRichTextInputToolbar size={size} />}
      label="Description*"
      source={"description"}
      {...props}
    />
    <SelectInput label="Type*" source={"type"} choices={[
      { id: 'digital', name: 'Digital' },
      { id: 'physical', name: 'Physical' },
    ]} />
    <ReferenceInput
      source="pot"
      reference="pots"
    >
      <AutocompleteInput
        filterToQuery={searchText => ({ title: searchText })}
        optionText="name"
        label="Pot*"
      />
    </ReferenceInput>
    <ReferenceInput {...props} source="largeImage" reference="media_objects">
      <ImageSelect title={"Large Image"} />
    </ReferenceInput>
    <ReferenceInput source="mediumImage" reference="media_objects">
      <ImageSelect title={"Medium Image"} />
    </ReferenceInput>
    <ReferenceInput source="smallImage" reference="media_objects">
      <ImageSelect title={"Small Image"} />
    </ReferenceInput>
  </CreateGuesser>
);

const MyRichTextInputToolbar = ({ size, ...props }) => {
  return (
    <RichTextInputToolbar {...props}>
      <FormatButtons size={size} />
      <ListButtons size={size} />
      <ClearButtons size={size} />
    </RichTextInputToolbar>
  );
}

export default CreatePrize;
