import React from "react";
import {CreateGuesser} from "@api-platform/admin";
import {FileField, ImageInput} from "react-admin";

const CreateMediaObject = props => (
  <CreateGuesser {...props}>
    <ImageInput source="file" label="Image">
      <FileField source="src" title="title" />
    </ImageInput>
  </CreateGuesser>
);
export default CreateMediaObject;
