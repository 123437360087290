import { EditGuesser, InputGuesser } from "@api-platform/admin";
import {
  ClearButtons,
  DefaultEditorOptions,
  FormatButtons,
  ListButtons,
  RichTextInput,
  RichTextInputToolbar
} from 'ra-input-rich-text';
import { AutocompleteInput, ReferenceInput, SelectInput } from "react-admin";
import ImageSelect from "../components/ImageSelect";
import React from "react";

const EditPrize = ({ size, ...props }) => {
  return (
    <EditGuesser {...props}>
      <InputGuesser source={"name"} />
      <RichTextInput
        editorOptions={MyEditorOptions}
        toolbar={<MyRichTextInputToolbar size={size} />}
        label="Description*"
        source={"description"}
        {...props}
      />
      <SelectInput label="Type*" source={"type"} choices={[
        { id: 'digital', name: 'Digital' },
        { id: 'physical', name: 'Physical' },
      ]} />
      <InputGuesser source={"active"} />
      <ReferenceInput
        source="pot"
        reference="pots"
      >
        <AutocompleteInput
          filterToQuery={searchText => ({ title: searchText })}
          optionText="name"
          label="Pot*"
        />
      </ReferenceInput>
      <ReferenceInput {...props} source="largeImage" reference="media_objects">
        <ImageSelect title={"Large Image"} />
      </ReferenceInput>
      <ReferenceInput source="mediumImage" reference="media_objects">
        <ImageSelect title={"Medium Image"} />
      </ReferenceInput>
      <ReferenceInput source="smallImage" reference="media_objects">
        <ImageSelect title={"Small Image"} />
      </ReferenceInput>
    </EditGuesser>
  )
};

const MyRichTextInputToolbar = ({ size, ...props }) => {
  return (
    <RichTextInputToolbar {...props}>
      <FormatButtons size={size} />
      <ListButtons size={size} />
      <ClearButtons size={size} />
    </RichTextInputToolbar>
  );
}

export const MyEditorOptions = {
  ...DefaultEditorOptions
};

export default EditPrize;
