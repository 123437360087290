import React from "react";
import {EditGuesser, InputGuesser} from "@api-platform/admin";

const EditPot = props => (
  <EditGuesser {...props}>
    <InputGuesser source={"name"} />
  </EditGuesser>
);

export default EditPot;
